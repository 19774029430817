import { store } from "@/store"
import { user, signupWithRedirect } from "@/composables/useAccount.js"

const supporterGuard = (to, from, next) => {
  if (user().roles.includes("supporter")) {
    return next()
  }
  return next("/")
}

const manageGuard = ({ params: { id } }, from, next) => {
  store.dispatch("subscription/checkIfExists", id)
    .then(() => next())
    .catch(() => next("/"))
}

const signupGuard = () => signupWithRedirect()

export {
  supporterGuard,
  manageGuard,
  signupGuard,
}
