import axios from "axios"

const connections = {
  namespaced: true,
  state: {
    connectionByStackId: {},
  },
  mutations: {
    SET_CONNECTION(state, { stackId, connection }) {
      if (Object.keys(connection).length > 0) {
        state.connectionByStackId = {
          ...state.connectionByStackId,
          [stackId]: {
            ...connection,
            options: {
              ...connection.options,
              // I don't send the client secret back to the frontend so
              // replace it with something "secret" to display it.
              clientSecret: "***********************************",
            },
          },
        }
      }
      else {
        state.connectionByStackId = {
          ...state.connectionByStackId,
          [stackId]: connection,
        }
      }
    },
  },
  getters: {
    hasConnection: state => stackId => Object.keys(state.connectionByStackId[stackId] || {}).length > 0,
  },
  actions: {
    async fetchEnterpriseConnection({ commit, getters }, stackId) {
      if (getters.hasConnection(stackId)) return

      const { data } = await axios.get(`instance/enterprise-connection/${stackId}`)
      commit("SET_CONNECTION", { stackId, connection: data })
    },
    async createEnterpriseConnection({ commit, getters }, { stackId, connection }) {
      if (getters.hasConnection(stackId)) return

      const { data } = await axios.post(`instance/enterprise-connection/${stackId}`, connection)
      commit("SET_CONNECTION", { stackId, connection: data })
    },
    async updateEnterpriseConnection({ commit, getters }, { stackId, connection }) {
      if (!getters.hasConnection(stackId)) return

      const { data } = await axios.patch(`instance/enterprise-connection/${stackId}`, connection)
      commit("SET_CONNECTION", { stackId, connection: data })
    },
    async deleteEnterpriseConnection({ commit, getters }, stackId) {
      if (!getters.hasConnection(stackId)) return

      await axios.delete(`instance/enterprise-connection/${stackId}`)
      commit("SET_CONNECTION", { stackId, connection: {} })
    },
  },
}

export {
  connections,
}
