import axios from "axios"

const DEFAULT_CHARGEBEE_SITE = import.meta.env.VITE_CHARGEBEE_SITE

let endpoints = {
  subscription: {},
  billing: {},
}
const defineEndpoints = (e) => {
  endpoints = e
}

const success = (resolve, reject, chargebee) => (hostedPageId) => {
  const { subscription } = hostedPageId
  const id = subscription?.id || hostedPageId
  chargebee.closeAll()
  axios.get(endpoints.subscription.GET(id))
    .then(resolve)
    .catch(reject)
}

const chargebeeCreate = (subdomain, planId, region, tester, site = DEFAULT_CHARGEBEE_SITE) => {
  return new Promise((resolve, reject) => {
    initChargebee(site).then((chargebee) => {
      const hostedPage = () => axios.post(endpoints.subscription.POST(), {
        subdomain,
        plan_id: planId,
        region,
        tester,
      }).then(({ data }) => data)
        .catch(reject)
      chargebee.openCheckout({
        hostedPage,
        success: success(resolve, reject, chargebee),
        error: reject,
        close: () => reject(new Error()),
      })
    }).catch(reject)
  })
}

const chargebeeUpdate = function (id, planId, site = DEFAULT_CHARGEBEE_SITE) {
  return new Promise((resolve, reject) => {
    initChargebee(site).then((chargebee) => {
      const hostedPage = () => axios.put(endpoints.subscription.PUT(id), {
        plan_id: planId,
      }).then(({ data }) => data)
        .catch(reject)
      chargebee.openCheckout({
        hostedPage,
        success: success(resolve, reject, chargebee),
        error: reject,
        subscriptionExtended: success(resolve, reject, chargebee),
        close: () => reject(new Error()),
      })
    })
  })
}

const createChargebeePortal = (resolve, reject, chargebee) => {
  const portalSession = () => axios.post(endpoints.billing.POST())
    .then(({ data }) => data)
    .catch(reject)
  chargebee.setPortalSession(portalSession)
  return chargebee.createChargebeePortal()
}

const chargeBeeEditBilling = (site = DEFAULT_CHARGEBEE_SITE) => {
  return new Promise((resolve, reject) => {
    initChargebee(site).then((chargebee) => {
      createChargebeePortal(resolve, reject, chargebee)
        .open({ close: resolve }, { sectionType: chargebee.portalSections.ADDRESS })
    }).catch(reject)
  })
}

const chargeBeeEditPayment = (site = DEFAULT_CHARGEBEE_SITE) => {
  return new Promise((resolve, reject) => {
    initChargebee(site).then((chargebee) => {
      createChargebeePortal(resolve, reject, chargebee)
        .open({ close: resolve }, { sectionType: chargebee.portalSections.PAYMENT_SOURCES })
    }).catch(reject)
  })
}

// Chargebee has no fucking npm package! You have to load it via script tag.
// But I don't want to load chargebee synchronously because I don't need it on initial page load!
// So here is a little asynchronous loader using script tag.
/* c8 ignore start */
const initChargebee = (site) => {
  return new Promise((resolve, reject) => {
    if (window.Chargebee) {
      return resolve(window.Chargebee)
    }

    const script = document.createElement("script")
    script.addEventListener("load", () => {
      const portalSections = window.Chargebee.getPortalSections()
      window.Chargebee = window.Chargebee.init({
        site,
        enableGTMTracking: import.meta.env.VITE_ENABLE_TRACKING === "true",
      })
      window.Chargebee.portalSections = portalSections
      resolve(window.Chargebee)
      script.remove()
    })
    script.addEventListener("error", (e) => {
      reject(e)
      script.remove()
    })
    script.async = true
    script.src = "https://js.chargebee.com/v2/chargebee.js"
    document.head.appendChild(script)
  })
}
/* c8 ignore end */

export {
  chargebeeCreate,
  chargebeeUpdate,
  chargeBeeEditBilling,
  chargeBeeEditPayment,
  defineEndpoints,
}
