import "@/assets/fonts.css"

import * as Sentry from "@sentry/vue"
import axios from "axios"
import { createApp } from "vue"
import { useRequestInterceptor, useResponseInterceptor } from "@/interceptor.js"
import App from "@/App.vue"
import router from "@/router"
import { vuetify } from "@/vuetify.js"
import { ToastPlugin } from "@/toast"
import { VueQueryPlugin } from "@tanstack/vue-query"
import { queryClient } from "@/query.js"
import { defineEndpoints as defineEndpointsForChargebee } from "@/chargebee.js"
import { auth0 } from "@/auth0.js"
import { store } from "@/store"

defineEndpointsForChargebee({
  subscription: {
    POST: () => "/customer/subscriptions",
    PUT: id => `/customer/subscriptions/${id}`,
    GET: id => `/customer/subscriptions/${id}`,
  },
  billing: {
    POST: () => "/customer/billing_details",
  },
})

axios.defaults.baseURL = import.meta.env.VITE_API_URL
useRequestInterceptor(axios, auth0)
useResponseInterceptor(axios)

const app = createApp(App)

if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    trackComponents: true,
    tracePropagationTargets: ["localhost", import.meta.env.DOMAIN_NAME, /^\//],
  })
}

app.use(router)
  .use(store)
  .use(auth0)
  .use(vuetify)
  .use(ToastPlugin)
  .use(VueQueryPlugin, { queryClient })
  .mount("#app")
