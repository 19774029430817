import { createRouter, createWebHistory } from "vue-router"
import { authGuard } from "@auth0/auth0-vue"
import Overview from "@/views/Overview.vue"
import { supporterGuard, manageGuard } from "@/router"
import { signupGuard } from "@/router"

const routes = () => ([
  {
    path: "/:pathMatch(.*)*",
    redirect: "/overview",
  },
  {
    path: "/",
    name: "Overview",
    alias: "/overview",
    component: Overview,
    beforeEnter: authGuard,
  },
  {
    path: "/signup",
    name: "SignUp",
    beforeEnter: signupGuard,
    component: {
      template: "<div/>",
    },
  },
  {
    path: "/new",
    name: "New",
    component: () => import("@/views/New.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/views/Account.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/manage/:id",
    name: "Manage",
    component: () => import("@/views/Manage.vue"),
    props: ({ params, query }) => ({
      id: params.id,
      scrollToSubscription: Boolean(query.scrollToSubscription),
    }),
    beforeEnter: [authGuard, manageGuard],
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: () => import("@/views/Invoice.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("@/views/Support.vue"),
    beforeEnter: [authGuard, supporterGuard],
  },
  {
    path: "/rollouts",
    name: "Rollouts",
    component: () => import("@/views/Rollouts.vue"),
    beforeEnter: [authGuard, supporterGuard],
  },
  {
    path: "/support/faq",
    name: "SupportFaq",
    component: () => import("@/views/SupportFaq.vue"),
    beforeEnter: [authGuard, supporterGuard],
  },
  {
    path: "/upload",
    name: "UploadSelect",
    component: () => import("@/views/SubscriptionSelectSkeleton.vue"),
    props: () => ({
      icon: "$mdiFileUpload",
      header: "file upload",
    }),
    beforeEnter: authGuard,
    children: [
      {
        path: ":id",
        name: "Upload",
        props: ({ params }) => ({ id: params.id }),
        component: () => import("@/views/InstanceProgressSkeleton.vue"),
        beforeEnter: manageGuard,
        children: [
          {
            path: "",
            name: "upload-default",
            component: () => import("@/views/Upload.vue"),
            props: ({ params }) => ({ id: params.id }),
          },
        ],
      },
    ],
  },
  {
    path: "/microsoft-entra-id",
    name: "MicrosoftEntraIdSelect",
    component: () => import("@/views/SubscriptionSelectSkeleton.vue"),
    props: () => ({
      icon: "$mdiMicrosoftWindows",
      header: "microsoft entra ID",
    }),
    beforeEnter: authGuard,
    children: [
      {
        path: ":id",
        name: "MicrosoftEntraId",
        props: ({ params }) => ({ id: params.id }),
        component: () => import("@/views/InstanceProgressSkeleton.vue"),
        beforeEnter: manageGuard,
        children: [
          {
            path: "",
            name: "microsoft-entra-id-default",
            component: () => import("@/views/MicrosoftEntraId.vue"),
            props: ({ params }) => ({ id: params.id }),
          },
        ],
      },
    ],
  },
  {
    path: "/data-sharing-opt-in",
    name: "DataCollectionOptIn",
    component: () => import("@/views/DataCollectionOptIn.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/partner-management",
    name: "PartnerManagement",
    component: () => import("@/views/PartnerManagement.vue"),
    beforeEnter: [authGuard, supporterGuard],
  },
])

const scrollBehavior = () => new Promise((resolve) => {
  setTimeout(() => {
    resolve({ top: 0 })
  }, 250)
})

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes(),
  scrollBehavior,
})

export default router
export {
  routes,
  scrollBehavior,
}
export * from "./guards.js"
