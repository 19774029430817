import axios from "axios"

const currency = {
  namespaced: true,
  state: {
    currencies: [],
  },
  mutations: {
    ADD_CURRENCIES(state, currencies) {
      state.currencies = currencies
    },
  },
  actions: {
    async fetchCurrencies({ commit, state }) {
      if (state.currencies.length === 0) {
        const { data } = await axios.get("/public/currencies")
        commit("ADD_CURRENCIES", data)
      }
    },
  },
}

export {
  currency,
}
