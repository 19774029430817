/*
This component is kind of a workaround because I couldn't find solution to the problem ¯\_(ツ)_/¯.
With Vue3, the new router and transitions I have the problem that the progress thingy jumps
from top to bottom, which doesn't look good.

So I declared a spinny thingy in App.vue which display is controlled here via provide/inject. As the vue
documentation states "it is recommended to keep any mutations to reactive state inside of the provider
whenever possible". Hence this should not be followed for future implementations as this is a corner case!
*/
<script setup>
import { inject, watch, onUnmounted } from "vue"

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

const updateFetchingIndicator = inject("updateFetchingIndicator")
watch(() => props.show, (val) => {
  updateFetchingIndicator(val)
}, { immediate: true })
onUnmounted(() => {
  updateFetchingIndicator(false)
})

</script>

<template>
  <v-slide-x-transition mode="out-in">
    <!--    uncomment to see the jumping-->
    <!--    <v-progress-circular-->
    <!--      v-if="show"-->
    <!--      class="center-vert-horiz"-->
    <!--    />-->
    <div
      v-if="show"
      key="placeholder"
    />
    <slot v-else />
  </v-slide-x-transition>
</template>
