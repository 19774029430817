<script setup>
import { ref, nextTick } from "vue"
import { useDisplay } from "vuetify"
import { useAccount } from "@/composables/useAccount.js"

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
})

const { logout } = useAccount()
const logo = ref(null)
const { smAndDown } = useDisplay()

function adjustLogoViewBox() {
  nextTick(() => {
    // without this the logo will be cropped, because the default viewbox of v-icon is 0 0 24 24
    const svg = logo.value?.$el.firstChild
    svg?.setAttribute("viewBox", "0 0 40 40")
  })
}
</script>

<template>
  <v-app-bar
    elevation="1"
    :density="smAndDown ? 'compact': 'default'"
  >
    <template #prepend>
      <v-app-bar-nav-icon
        v-if="smAndDown"
        color="secondary"
      />
      <v-icon
        v-else
        ref="logo"
        style="font-size: 2.45em"
      >
        <!--a little hack, but hey it never works without one :P-->
        {{ adjustLogoViewBox() }}
        $logo
      </v-icon>
    </template>

    <v-app-bar-title>
      <div class="d-flex justify-center align-center ga-2">
        <v-icon
          v-if="smAndDown"
          ref="logo"
        >
          <!--a little hack, but hey it never works without one :P-->
          {{ adjustLogoViewBox() }}
          $logo
        </v-icon>
        <slot />
      </div>
    </v-app-bar-title>

    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      location="bottom"
    />

    <v-btn
      variant="plain"
      icon
      @click="logout"
    >
      <v-icon color="secondary">
        $mdiPower
      </v-icon>
      <v-tooltip activator="parent">
        Logout
      </v-tooltip>
    </v-btn>
  </v-app-bar>
</template>
