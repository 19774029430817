<script setup>
const links = [
  {
    href: "https://www.paessler.com",
    text: `© ${new Date().getFullYear()} paessler gmbh`,
  },
  {
    href: "https://www.paessler.com/company/contact#imprint",
    text: "imprint",
  },
  {
    href: "https://www.paessler.com/company/privacypolicy",
    text: "privacy policy",
  },
  {
    href: "https://www.paessler.com/company/terms",
    text: "terms & conditions",
  },
  {
    href: "https://www.paessler.com/company/about-us",
    text: "about paessler",
  },
]
</script>

<template>
  <v-footer
    app
    absolute
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="{href, text} in links"
        :key="href"
        tag="a"
        :href="href"
        variant="text"
        size="small"
        rel="noopener noreferrer"
        target="_blank"
        color="secondary"
      >
        {{ text }}
      </v-btn>
    </v-row>
  </v-footer>
</template>
