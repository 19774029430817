const isObject = obj => obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function"

const keysToCamel = (obj) => {
  const snakeToCamel = s => s.replace(/([_][a-z0-9])/g, g => g.toUpperCase().replace("_", ""))

  if (isObject(obj)) {
    return Object.entries(obj).reduce((prev, [key, value]) => {
      if (typeof value === "object" && value !== null) {
        return { ...prev, [snakeToCamel(key)]: keysToCamel(value) }
      }
      return { ...prev, [snakeToCamel(key)]: value }
    }, {})
  }
  else if (Array.isArray(obj)) {
    return obj.map(it => keysToCamel(it))
  }
  else {
    return obj
  }
}

const useResponseInterceptor = ({ interceptors }) => {
  interceptors.response.use(({ data, ...rest }) => {
    // skip the key conversion from snake case to camel case.
    // this is needed because we use file names as keys, which can be snake case, for the upload functionality.
    if (rest.config.disableKeysToCamel) {
      return { data, ...rest }
    }

    if (isObject(data)) {
      return { data: keysToCamel(data), ...rest }
    }

    if (Array.isArray(data)) {
      return { data: data.map(it => keysToCamel(it)), ...rest }
    }

    return { data, ...rest }
  })
}

const useRequestInterceptor = ({ interceptors, CancelToken }, auth0) => {
  interceptors.request.use(async (config) => {
    try {
      config.headers.Authorization = await auth0.getAccessTokenSilently()
      return config
    }
    catch (e) {
      await auth0.logout()
      return {
        ...config,
        cancelToken: new CancelToken(cancel => cancel(e)),
      }
    }
  })
}

export {
  useRequestInterceptor,
  useResponseInterceptor,
}
