import { createAuth0 } from "@auth0/auth0-vue"

const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    redirect_uri: window.location.origin,
  },
  // use cookies and store the token in the local storage in e2e tests
  // this makes login for e2e test much easier
  cacheLocation: import.meta.env.VITE_AUTH0_CACHE_LOCATION,
  useRefreshTokens: !import.meta.env.VITE_AUTH0_USE_COOKIES,
})

export {
  auth0,
}
