import axios from "axios"
import { chargebeeCreate, chargebeeUpdate } from "@/chargebee.js"
import { toast } from "@/toast"
import { queryClient } from "@/query.js"

const path = ext => ext ? `/customer/subscriptions/${ext}` : "/customer/subscriptions"
const checkStackStatusCreateOrUpdate = function (stackId) {
  return axios.post(`/customer/stacks/${stackId}/status`, { accepted_states: ["CREATE_COMPLETE", "UPDATE_COMPLETE"] })
}
const checkInstanceStatusRunning = function (stackId) {
  return axios.post(`/customer/instances/${stackId}/status`, { accepted_states: ["RUNNING"] })
}

const subscription = {
  namespaced: true,
  state: {
    subscriptions: [],
    polls: new Set(),
    isCached: false,
  },
  mutations: {
    ADD_POLL(state, id) {
      state.polls.add(id)
    },
    CLEAR_POLL(state, { id }) {
      state.polls.delete(id)
    },
    CLEAR_CACHE(state) {
      state.isCached = false
    },
    SET_SUBSCRIPTIONS(state, subscriptions) {
      state.subscriptions = subscriptions
      state.isCached = true
    },
    ADD_SUBSCRIPTION(state, subscription) {
      state.subscriptions = [...state.subscriptions, subscription]
    },
    UPDATE_SUBSCRIPTION(state, subscription) {
      state.subscriptions = state.subscriptions.map(sub => sub.id === subscription.id ? subscription : sub)
    },
    REMOVE_SUBSCRIPTION(state, { id }) {
      state.subscriptions = state.subscriptions.filter(sub => sub.id !== id)
    },
  },

  actions: {
    pollSubscription({ state, commit }, { id }) {
      if (state.polls.has(id)) {
        return
      }

      const poll = async () => {
        try {
          const { data } = await axios.get(path(id))
          const subscription = state.subscriptions.find(subscription => subscription.id === id)
          if (subscription.stackStatus !== "ready" && data.stackStatus === "ready") {
            await queryClient.invalidateQueries({ queryKey: ["fetchAllPermissions"], refetchType: "active" })
          }
          commit("UPDATE_SUBSCRIPTION", data)
          if (data.stackStatus === "ready") {
            commit("CLEAR_POLL", data)
          }
          else {
            setTimeout(poll, 5000)
            commit("ADD_POLL", id)
          }
        }
        catch (e) {
          commit("CLEAR_POLL", { id })
          toast.error(e)
        }
      }

      return poll()
    },
    async fetchSubscriptions({ state, commit }) {
      if (state.isCached) {
        return
      }

      try {
        const { data } = await axios.get(path())
        commit("SET_SUBSCRIPTIONS", data)
      }
      catch (e) {
        toast.error(e)
      }
    },
    async createSubscription({ commit }, { subdomain, id, name, region, tester }) {
      // the returned booleans are needed later to determine if to route back to '/' or not
      try {
        const { data } = await chargebeeCreate(subdomain, id, region, tester)
        commit("ADD_SUBSCRIPTION", data)
        toast.success(`Subscription ${name} created`)
        // https://jira.paesslergmbh.de/browse/PPHM-529:
        // Chargebee doesn't send this wanted information automatically in their integration
        window.dataLayer?.push({ event: "subscription", subscriptionId: data.id })
        return true
      }
      catch (e) {
        toast.error(e)
        return false
      }
    },
    async updateSubscription({ commit, dispatch }, { id, planId, name, stackId }) {
      try {
        await Promise.all([
          checkInstanceStatusRunning(stackId),
          checkStackStatusCreateOrUpdate(stackId),
        ])
        const { data } = await chargebeeUpdate(id, planId)
        commit("UPDATE_SUBSCRIPTION", data)
        dispatch("pollSubscription", { id })
        toast.success(`Subscription updated to ${name}`)
      }
      catch (e) {
        toast.error(e)
      }
    },
    async cancelSubscription({ commit }, { id, cancelReasonCode }) {
      // the returned booleans are needed later to determine if to route back to '/' or not
      try {
        const { data } = await axios.delete(path(id) + `?subscription_cancel_reason_code=${cancelReasonCode}`)
        if (data.subscriptionStatus === "cancelled") {
          commit("REMOVE_SUBSCRIPTION", data)
          commit("instance/REMOVE_TIMEZONE", data, { root: true })
        }
        else {
          commit("UPDATE_SUBSCRIPTION", data)
        }
        toast.success("Subscription cancelled")
        return true
      }
      catch (e) {
        toast.error(e)
        return false
      }
    },
    async restoreSubscription({ commit }, { id }) {
      try {
        await axios.post(path(`${id}/reactivate`))
        const { data } = await axios.get(path(id))
        commit("UPDATE_SUBSCRIPTION", data)
        toast.success("Subscription has been reactivated")
      }
      catch (e) {
        toast.error(e)
      }
    },
    async changeSubdomain({ commit, dispatch }, { id, subdomain, stackId }) {
      try {
        await Promise.all([
          checkInstanceStatusRunning(stackId),
          checkStackStatusCreateOrUpdate(stackId),
        ])
        const { data } = await axios.put(path(`${id}/subdomain`), { subdomain })
        commit("UPDATE_SUBSCRIPTION", data)
        dispatch("pollSubscription", data)
      }
      catch (e) {
        toast.clear() // validateDomain() will trigger a toast, so without clearing it, the error will not be shown
        toast.error(e)
      }
    },
    checkIfExists({ state }, id) {
      if (state.subscriptions.some(sub => sub.id === id)) {
        return Promise.resolve(`Subscription ${id} already stored.`)
      }
      return axios.get(path(id))
    },
  },
}

export {
  subscription,
}
