import axios from "axios"
import { timezones } from "@/date.js"
import { toast } from "@/toast"

const path = stackId => `/instance/${stackId}/timezone`

const instance = {
  namespaced: true,
  state: {
    timezones: [],
  },
  getters: {
    findTimezoneById: state => stackId => state.timezones.find(zone => zone.stackId === stackId) || {},
  },
  mutations: {
    ADD_TIMEZONE(state, { stackId, timezone }) {
      state.timezones = [...state.timezones, { stackId, timezone }]
    },
    UPDATE_TIMEZONE(state, { stackId, timezone }) {
      state.timezones = state.timezones.map(zone => zone.stackId === stackId ? { stackId, timezone } : zone)
    },
    REMOVE_TIMEZONE(state, { stackId }) {
      state.timezones = state.timezones.filter(zone => zone.stackId !== stackId)
    },
  },
  actions: {
    async fetchTimezone({ state, commit }, stackId) {
      if (state.timezones.some(zone => zone.stackId === stackId)) {
        return
      }

      try {
        const { data } = await axios.get(path(stackId))
        commit("ADD_TIMEZONE", { ...data, stackId })
      }
      catch (e) {
        toast.error(e)
      }
    },
    async changeTimezone({ commit }, { stackId, timezone }) {
      try {
        const { data } = await axios.post(path(stackId), { timezone })
        commit("UPDATE_TIMEZONE", { ...data, stackId })
        toast.success(`Successfully changed Timezone to ${timezones.find(({ Id }) => Id === data.timezone).DisplayName}`)
      }
      catch (e) {
        toast.error(e)
      }
    },
  },
}

export {
  instance,
}
