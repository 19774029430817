<script setup>
import { provide, ref } from "vue"
import { useAccount } from "@/composables/useAccount.js"
import PFooter from "@/components/presentational/PFooter.vue"
import PAppBar from "@/components/presentational/PAppBar.vue"
import PNavigationDrawer from "@/components/presentational/PNavigationDrawer.vue"

const { isAuthenticated } = useAccount()
const drawer = ref(true)
const navigation = [
  {
    title: "Overview",
    icon: "$mdiViewDashboard",
    to: "/",
    show: () => true,
  },
  {
    title: "Invoices",
    icon: "$mdiReceiptText",
    to: "/invoices",
    show: () => true,
  },
  {
    title: "Upload",
    icon: "$mdiFileUpload",
    to: "/upload",
    show: () => true,
  },
  {
    title: "Microsoft Entra ID",
    icon: "$mdiMicrosoftWindows",
    to: "/microsoft-entra-id",
    show: () => true,
    new: true,
  },
  {
    title: "Support",
    icon: "$mdiLadybug",
    to: "/support",
    show: ({ roles }) => roles?.includes("supporter"),
  },
  {
    title: "Rollouts",
    icon: "$mdiPackage",
    to: "/rollouts",
    show: ({ roles }) => roles?.includes("supporter"),
  },
  {
    title: "Partner Management",
    icon: "$mdiAccountBoxPlusOutline",
    to: "/partner-management",
    show: ({ roles }) => roles?.includes("supporter"),
  },
]
const showFetchingIndicator = ref(false)
const updateFetchingIndicator = (val) => {
  showFetchingIndicator.value = val
}
provide("updateFetchingIndicator", updateFetchingIndicator)
</script>

<template>
  <v-app>
    <template v-if="isAuthenticated">
      <p-navigation-drawer
        v-model="drawer"
        :items="navigation"
      />
      <p-app-bar
        @click="drawer = true"
      />
      <v-main>
        <router-view
          v-slot="{ Component, route }"
          appear
        >
          <v-slide-x-transition mode="out-in">
            <component
              :is="Component"
              :key="route.name"
            />
          </v-slide-x-transition>
        </router-view>
      </v-main>
      <v-progress-circular
        v-if="showFetchingIndicator"
        class="center-vert-horiz"
      />
      <v-snackbar
        :color="$toast.type"
        :model-value="$toast.show"
        multi-line
      >
        {{ $toast.message }}
        <template #actions>
          <v-btn
            color="white"
            @click="$toast.clear"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <p-footer />
    </template>
    <v-progress-circular
      v-else
      class="center-vert-horiz"
    />
  </v-app>
</template>

<style lang="scss">
.center-vert-horiz {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
}
</style>
