import axios from "axios"

const cancellationReason = {
  namespaced: true,
  state: {
    codes: [],
  },
  mutations: {
    ADD_CODES(state, data) {
      state.codes = data.reasons
    },
  },
  actions: {
    async fetchCancellationReasonCodes({ commit, state }) {
      if (state.codes.length === 0) {
        const { data } = await axios.get("/supporter/settings/cancellationreasons")
        commit("ADD_CODES", data)
      }
    },
  },
}

export {
  cancellationReason,
}
