import axios from "axios"

const region = {
  namespaced: true,
  state: {
    regions: [],
  },
  mutations: {
    ADD_REGIONS(state, regions) {
      state.regions = regions
    },
  },
  actions: {
    async fetchRegions({ commit, state }) {
      if (state.regions.length === 0) {
        const { data } = await axios.get("/public/regions")
        commit("ADD_REGIONS", data)
      }
    },
  },
}

export {
  region,
}
