<script>
import { createNamespacedHelpers } from "vuex"

const { mapState, mapActions } = createNamespacedHelpers("region")

export default {
  name: "CRegion",
  emits: ["created"],
  data() {
    return {
      isFetching: this.$store.state.region.regions.length === 0,
    }
  },
  computed: {
    ...mapState(["regions"]),
    standardizedRegions() {
      return this.regions.map((region) => {
        const renamedLocation = {
          Spain: "Madrid",
          UAE: "Dubai",
        }[region.location] || region.location
        const renamedNation = {
          "South America": "North/South America",
          "Canada": "North/South America",
          "US East": "North/South America",
          "US West": "North/South America",
          "Israel": "Middle East",
        }[region.nation] || region.nation

        return {
          ...region,
          nation: renamedNation,
          location: renamedLocation,
          name: `${renamedLocation} - ${renamedNation}`,
        }
      })
    },
    regionsByCode() {
      return this.standardizedRegions.reduce((previousRegion, currentRegion) => ({
        ...previousRegion,
        [currentRegion.code]: currentRegion,
      }), {})
    },
    regionsByNation() {
      const groupedByNation = this.standardizedRegions.reduce((acc, region) => {
        acc[region.nation] = [...(acc[region.nation] || []), region]
        return acc
      }, {})

      return ["Europe", "North/South America", "Asia Pacific", "Middle East", "Africa"]
        .flatMap((nation) => {
          if (groupedByNation[nation]) {
            return [
              { props: { header: nation } },
              { props: { divider: true } },
              ...groupedByNation[nation].sort((a, b) => a.location.localeCompare(b.location)),
            ]
          }
          return []
        })
    },
    nearestRegion() {
      const userLongitude = this.$store.state.visitor.visitor.longitude
      const userLatitude = this.$store.state.visitor.visitor.latitude
      const closestAwsRegion = this.getClosestAwsRegion(userLongitude, userLatitude)
      return this.regionsByCode[closestAwsRegion] || {}
    },
  },
  async created() {
    await this.fetchRegions()
    this.isFetching = false
    this.$emit("created", this.nearestRegion)
  },
  methods: {
    ...mapActions(["fetchRegions"]),
    distanceBetweenTwoLongLats(long1, lat1, long2, lat2) {
      const R = 6373.0
      const dlonDegrees = long2 - long1
      const dlatDegrees = lat2 - lat1
      const dlon = dlonDegrees * (Math.PI / 180)
      const dlat = dlatDegrees * (Math.PI / 180)
      const a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.pow(Math.sin(dlon / 2), 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      return R * c
    },
    getClosestAwsRegion(userLong, userLat) {
      let minDistance = 10000000000
      let closestAwsRegion = "eu-west-1"
      const awsRegionsMap = this.$store.state.region.regions
      for (let i = 0; i < awsRegionsMap.length; i += 1) {
        const region = awsRegionsMap[i]
        const distance = this.distanceBetweenTwoLongLats(userLong, userLat, region.longitude, region.latitude)
        if (distance < minDistance) {
          minDistance = distance
          closestAwsRegion = region.code
        }
      }
      return closestAwsRegion
    },
  },
  render() {
    return this.$slots.default({
      regions: this.regionsByCode,
      regionsByNation: this.regionsByNation,
      nearestRegion: this.nearestRegion,
      isFetching: this.isFetching,
      getClosestAwsRegion: this.getClosestAwsRegion,
      distanceBetweenTwoLongLats: this.distanceBetweenTwoLongLats,
      fetchRegions: this.fetchRegions,
    })
  },
}
</script>
