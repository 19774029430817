<script setup>
import { computed } from "vue"

const props = defineProps({
  complete: {
    type: Boolean,
    default: false,
  },
  ongoing: {
    type: Boolean,
    default: false,
  },
  failed: {
    type: Boolean,
    default: false,
  },
})

const statusColorCssClass = computed(() => {
  if (props.failed) {
    return "bg-error"
  }
  if (props.complete) {
    return "bg-success"
  }
  return "bg-info"
})
</script>

<template>
  <v-card-item :class="statusColorCssClass">
    <v-card-title class="d-flex align-center text-body-1 font-weight-medium animate-bg-color">
      <v-icon>
        <template v-if="failed">
          $mdiInformationOutline
        </template>
        <template v-else-if="complete">
          $mdiCheckCircleOutline
        </template>
        <template v-else>
          $mdiProgressClock
        </template>
      </v-icon>
      <div class="flex-grow-1 mx-2 text-uppercase">
        <v-slide-x-transition mode="out-in">
          <span v-if="failed">
            creation failed
          </span>
          <span v-else-if="ongoing">
            preparing instance
          </span>
          <span v-else-if="complete">
            instance ready
          </span>
          <span v-else>
            getting status
          </span>
        </v-slide-x-transition>
      </div>

      <v-btn
        v-if="failed"
        icon
        color="white"
        href="https://www.paessler.com/support/contact/customerservice"
        target="_blank"
        variant="plain"
        density="compact"
      >
        <v-icon>
          $mdiHelpCircle
        </v-icon>
        <v-tooltip
          activator="parent"
          location="left"
        >
          Ask our customer service
        </v-tooltip>
      </v-btn>
    </v-card-title>
  </v-card-item>
</template>

<style scoped>
.animate-bg-color {
  transition: background-color 0.25s ease-in;
}
</style>
