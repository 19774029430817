<script>
import { createNamespacedHelpers } from "vuex"

const { mapState, mapActions } = createNamespacedHelpers("pricing")

export default {
  name: "CPricing",
  props: {
    activePlanId: {
      type: String,
      default: "",
    },
    periodUnit: {
      type: String,
      default: "month",
    },
  },
  emits: ["fetched"],
  data() {
    return {
      isFetching: this.$store.state.pricing.pricingPlans.length === 0,
    }
  },
  computed: {
    ...mapState(["pricingPlans"]),
    normalizedPlans() {
      const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
      }
      const priceFormats = {
        EUR: new Intl.NumberFormat(undefined, {
          ...options,
          currency: "EUR",
        }),
        USD: new Intl.NumberFormat(undefined, {
          ...options,
          currency: "USD",
        }),
      }

      return this.pricingPlans
        // remove all debug, beta and plans before 2022
        .filter(({ id, bundle }) => {
          // IMPORTANT! This old plan is still used because it's the original trial plan before
          // plans depending on the currency were introduced.
          if (id === "prod-28-08-2017_prtg-trial") {
            return true
          }

          // e.g. prod-01-01-2022 => ["prod", ["01", "01", "2022"]]
          const [head, ...tail] = bundle.split("-")
          // valid plans always start with prod-xx-xx-xxxx
          if (head !== "prod") {
            return false
          }

          // reverse the date to xxxx-xx-xx so js Date can convert it to Date object
          // all valid plans have an older date then 01.01.2022 in their bundle name
          return new Date(`${tail[2]}-${tail[1]}-${tail[0]}`) > new Date("2022-01-01")
        })
        .map(plan => ({
          ...plan,
          price: priceFormats[plan.currencyCode].format(plan.price / 100),
          active: plan.id === this.activePlanId,
        }))
    },
    baseMonthAnnualPlans() {
      return this.normalizedPlans
        .filter(({ trial }) => !trial)
        .filter(({ id, periodUnit }) => periodUnit === "year" || id !== this.activePlanId)
        .filter(({ period }) => period === 1)
        .filter(({ periodUnit }) =>
          this.canHaveMonthlyAndAnnualPlans ? periodUnit === this.periodUnit : periodUnit === "year",
        )
        .filter(({ currencyCode }) => {
          if (this.hasPlan) {
            return currencyCode === this.currentPricingPlan.currencyCode
          }

          return currencyCode === this.visitor.currencyCode
        })
    },
    multiAnnualPlans() {
      return this.normalizedPlans
        .filter(({ trial }) => !trial)
        .filter(({ period }) => period > 1)
        .filter(({ currencyCode }) => {
          if (this.hasPlan) {
            return currencyCode === this.currentPricingPlan.currencyCode
          }

          return currencyCode === this.visitor.currencyCode
        })
    },
    trialPlan() {
      return this.normalizedPlans.find(
        ({ trial, currencyCode }) => trial && currencyCode === this.visitor.currencyCode,
      ) || {}
    },
    visitor() {
      return this.$store.state.visitor.visitor
    },
    currentPricingPlan() {
      return this.normalizedPlans.find(({ id }) => id === this.activePlanId) || {}
    },
    canHaveMonthlyAndAnnualPlans() {
      return this.activePlanId === "" || /day|month/.test(this.currentPricingPlan.periodUnit)
    },
    hasPlan() {
      return Object.entries(this.currentPricingPlan).length > 0
    },
  },
  async created() {
    await this.$store.dispatch("visitor/fetchVisitor")
    await this.fetchPricingPlans()
    this.isFetching = false
    this.$emit("fetched")
  },
  methods: {
    ...mapActions(["fetchPricingPlans"]),
  },
  render() {
    return this.$slots.default({
      baseMonthAnnualPlans: this.baseMonthAnnualPlans,
      currentPricingPlan: this.currentPricingPlan,
      isFetching: this.isFetching,
      trialPlan: this.trialPlan,
      multiAnnualPlans: this.multiAnnualPlans,
      canHaveMonthlyAndAnnualPlans: this.canHaveMonthlyAndAnnualPlans,
    })
  },
}
</script>
