import { reactive, toRefs } from "vue"

let toast

const ToastPlugin = {
  install(app) {
    const initialState = () => ({
      message: "",
      show: false,
      type: "",
    })
    const state = reactive(initialState())
    let timeout = null

    const fnForEachToastType = () => ["error", "success"].reduce((fns, type) => ({
      ...fns,
      [type]: (messageObj) => {
        if (state.message) {
          return
        }

        const message = messageObj instanceof Error
          ? messageObj.response?.data?.message || messageObj.message
          : messageObj

        if (message) {
          state.message = message
          state.type = type
          state.show = true
          const delay = Math.min(Math.max(message.length * 50, 4000), 9000)
          timeout = setTimeout(() => {
            Object.assign(state, initialState())
          }, delay)
        }
      },
    }), {})

    const clear = () => {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      Object.assign(state, initialState())
    }

    toast = reactive({
      ...fnForEachToastType(),
      ...toRefs(state),
      clear,
      data: {
        ...toRefs(state),
      },
    })

    app.config.globalProperties.$toast = toast
  },
}

export {
  ToastPlugin,
  toast,
}
