import { createStore } from "vuex"
import {
  pricing,
  subscription,
  invoice,
  support,
  instance,
  region,
  currency,
  cancellationReason,
  visitor,
  upload,
  connections,
} from "@/store/modules"

export const store = createStore({
  strict: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    pricing,
    subscription,
    invoice,
    support,
    instance,
    region,
    currency,
    cancellationReason,
    visitor,
    upload,
    connections,
  },
})
