import axios from "axios"

const pricing = {
  namespaced: true,
  state: {
    pricingPlans: [],
  },
  mutations: {
    ADD_PRICING_PLANS(state, pricingPlans) {
      if (pricingPlans) {
        state.pricingPlans = pricingPlans
      }
    },
  },
  actions: {
    async fetchPricingPlans({ commit, state }) {
      if (state.pricingPlans.length === 0) {
        const { data } = await axios.get("/public/plans?all_bundles=1")
        commit("ADD_PRICING_PLANS", data)
      }
    },
  },
}

export {
  pricing,
}
