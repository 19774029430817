const isDateValid = date => date instanceof Date && !isNaN(date)

const timezones = [
  {
    Id: "Dateline Standard Time",
    DisplayName: "(UTC-12:00) International Date Line West",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "UTC-11",
    DisplayName: "(UTC-11:00) Coordinated Universal Time-11",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Aleutian Standard Time",
    DisplayName: "(UTC-10:00) Aleutian Islands",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Hawaiian Standard Time",
    DisplayName: "(UTC-10:00) Hawaii",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Marquesas Standard Time",
    DisplayName: "(UTC-09:30) Marquesas Islands",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Alaskan Standard Time",
    DisplayName: "(UTC-09:00) Alaska",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "UTC-09",
    DisplayName: "(UTC-09:00) Coordinated Universal Time-09",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Pacific Standard Time (Mexico)",
    DisplayName: "(UTC-08:00) Baja California",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "UTC-08",
    DisplayName: "(UTC-08:00) Coordinated Universal Time-08",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Pacific Standard Time",
    DisplayName: "(UTC-08:00) Pacific Time (US \u0026 Canada)",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "US Mountain Standard Time",
    DisplayName: "(UTC-07:00) Arizona",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Mountain Standard Time (Mexico)",
    DisplayName: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Mountain Standard Time",
    DisplayName: "(UTC-07:00) Mountain Time (US \u0026 Canada)",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Central America Standard Time",
    DisplayName: "(UTC-06:00) Central America",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Central Standard Time",
    DisplayName: "(UTC-06:00) Central Time (US \u0026 Canada)",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Easter Island Standard Time",
    DisplayName: "(UTC-06:00) Easter Island",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Central Standard Time (Mexico)",
    DisplayName: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Canada Central Standard Time",
    DisplayName: "(UTC-06:00) Saskatchewan",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "SA Pacific Standard Time",
    DisplayName: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Eastern Standard Time (Mexico)",
    DisplayName: "(UTC-05:00) Chetumal",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Eastern Standard Time",
    DisplayName: "(UTC-05:00) Eastern Time (US \u0026 Canada)",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Haiti Standard Time",
    DisplayName: "(UTC-05:00) Haiti",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Cuba Standard Time",
    DisplayName: "(UTC-05:00) Havana",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "US Eastern Standard Time",
    DisplayName: "(UTC-05:00) Indiana (East)",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Turks And Caicos Standard Time",
    DisplayName: "(UTC-05:00) Turks and Caicos",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Paraguay Standard Time",
    DisplayName: "(UTC-04:00) Asuncion",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Atlantic Standard Time",
    DisplayName: "(UTC-04:00) Atlantic Time (Canada)",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Venezuela Standard Time",
    DisplayName: "(UTC-04:00) Caracas",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Central Brazilian Standard Time",
    DisplayName: "(UTC-04:00) Cuiaba",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "SA Western Standard Time",
    DisplayName: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Pacific SA Standard Time",
    DisplayName: "(UTC-04:00) Santiago",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Newfoundland Standard Time",
    DisplayName: "(UTC-03:30) Newfoundland",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Tocantins Standard Time",
    DisplayName: "(UTC-03:00) Araguaina",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "E. South America Standard Time",
    DisplayName: "(UTC-03:00) Brasilia",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "SA Eastern Standard Time",
    DisplayName: "(UTC-03:00) Cayenne, Fortaleza",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Argentina Standard Time",
    DisplayName: "(UTC-03:00) City of Buenos Aires",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Greenland Standard Time",
    DisplayName: "(UTC-03:00) Greenland",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Montevideo Standard Time",
    DisplayName: "(UTC-03:00) Montevideo",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Magallanes Standard Time",
    DisplayName: "(UTC-03:00) Punta Arenas",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Saint Pierre Standard Time",
    DisplayName: "(UTC-03:00) Saint Pierre and Miquelon",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Bahia Standard Time",
    DisplayName: "(UTC-03:00) Salvador",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "UTC-02",
    DisplayName: "(UTC-02:00) Coordinated Universal Time-02",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Mid-Atlantic Standard Time",
    DisplayName: "(UTC-02:00) Mid-Atlantic - Old",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Azores Standard Time",
    DisplayName: "(UTC-01:00) Azores",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Cape Verde Standard Time",
    DisplayName: "(UTC-01:00) Cabo Verde Is.",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "UTC",
    DisplayName: "(UTC) Coordinated Universal Time",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "GMT Standard Time",
    DisplayName: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Greenwich Standard Time",
    DisplayName: "(UTC+00:00) Monrovia, Reykjavik",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Sao Tome Standard Time",
    DisplayName: "(UTC+00:00) Sao Tome",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Morocco Standard Time",
    DisplayName: "(UTC+01:00) Casablanca",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "W. Europe Standard Time",
    DisplayName: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Central Europe Standard Time",
    DisplayName: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Romance Standard Time",
    DisplayName: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Central European Standard Time",
    DisplayName: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "W. Central Africa Standard Time",
    DisplayName: "(UTC+01:00) West Central Africa",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Jordan Standard Time",
    DisplayName: "(UTC+02:00) Amman",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "GTB Standard Time",
    DisplayName: "(UTC+02:00) Athens, Bucharest",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Middle East Standard Time",
    DisplayName: "(UTC+02:00) Beirut",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Egypt Standard Time",
    DisplayName: "(UTC+02:00) Cairo",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "E. Europe Standard Time",
    DisplayName: "(UTC+02:00) Chisinau",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Syria Standard Time",
    DisplayName: "(UTC+02:00) Damascus",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "West Bank Standard Time",
    DisplayName: "(UTC+02:00) Gaza, Hebron",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "South Africa Standard Time",
    DisplayName: "(UTC+02:00) Harare, Pretoria",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "FLE Standard Time",
    DisplayName: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Israel Standard Time",
    DisplayName: "(UTC+02:00) Jerusalem",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Kaliningrad Standard Time",
    DisplayName: "(UTC+02:00) Kaliningrad",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Sudan Standard Time",
    DisplayName: "(UTC+02:00) Khartoum",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Libya Standard Time",
    DisplayName: "(UTC+02:00) Tripoli",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Namibia Standard Time",
    DisplayName: "(UTC+02:00) Windhoek",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Arabic Standard Time",
    DisplayName: "(UTC+03:00) Baghdad",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Turkey Standard Time",
    DisplayName: "(UTC+03:00) Istanbul",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Arab Standard Time",
    DisplayName: "(UTC+03:00) Kuwait, Riyadh",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Belarus Standard Time",
    DisplayName: "(UTC+03:00) Minsk",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Russian Standard Time",
    DisplayName: "(UTC+03:00) Moscow, St. Petersburg",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "E. Africa Standard Time",
    DisplayName: "(UTC+03:00) Nairobi",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Iran Standard Time",
    DisplayName: "(UTC+03:30) Tehran",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Arabian Standard Time",
    DisplayName: "(UTC+04:00) Abu Dhabi, Muscat",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Astrakhan Standard Time",
    DisplayName: "(UTC+04:00) Astrakhan, Ulyanovsk",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Azerbaijan Standard Time",
    DisplayName: "(UTC+04:00) Baku",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Russia Time Zone 3",
    DisplayName: "(UTC+04:00) Izhevsk, Samara",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Mauritius Standard Time",
    DisplayName: "(UTC+04:00) Port Louis",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Saratov Standard Time",
    DisplayName: "(UTC+04:00) Saratov",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Georgian Standard Time",
    DisplayName: "(UTC+04:00) Tbilisi",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Volgograd Standard Time",
    DisplayName: "(UTC+04:00) Volgograd",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Caucasus Standard Time",
    DisplayName: "(UTC+04:00) Yerevan",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Afghanistan Standard Time",
    DisplayName: "(UTC+04:30) Kabul",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "West Asia Standard Time",
    DisplayName: "(UTC+05:00) Ashgabat, Tashkent",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Ekaterinburg Standard Time",
    DisplayName: "(UTC+05:00) Ekaterinburg",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Pakistan Standard Time",
    DisplayName: "(UTC+05:00) Islamabad, Karachi",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Qyzylorda Standard Time",
    DisplayName: "(UTC+05:00) Qyzylorda",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "India Standard Time",
    DisplayName: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Sri Lanka Standard Time",
    DisplayName: "(UTC+05:30) Sri Jayawardenepura",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Nepal Standard Time",
    DisplayName: "(UTC+05:45) Kathmandu",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Central Asia Standard Time",
    DisplayName: "(UTC+06:00) Astana",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Bangladesh Standard Time",
    DisplayName: "(UTC+06:00) Dhaka",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Omsk Standard Time",
    DisplayName: "(UTC+06:00) Omsk",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Myanmar Standard Time",
    DisplayName: "(UTC+06:30) Yangon (Rangoon)",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "SE Asia Standard Time",
    DisplayName: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Altai Standard Time",
    DisplayName: "(UTC+07:00) Barnaul, Gorno-Altaysk",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "W. Mongolia Standard Time",
    DisplayName: "(UTC+07:00) Hovd",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "North Asia Standard Time",
    DisplayName: "(UTC+07:00) Krasnoyarsk",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "N. Central Asia Standard Time",
    DisplayName: "(UTC+07:00) Novosibirsk",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Tomsk Standard Time",
    DisplayName: "(UTC+07:00) Tomsk",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "China Standard Time",
    DisplayName: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "North Asia East Standard Time",
    DisplayName: "(UTC+08:00) Irkutsk",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Singapore Standard Time",
    DisplayName: "(UTC+08:00) Kuala Lumpur, Singapore",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "W. Australia Standard Time",
    DisplayName: "(UTC+08:00) Perth",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Taipei Standard Time",
    DisplayName: "(UTC+08:00) Taipei",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Ulaanbaatar Standard Time",
    DisplayName: "(UTC+08:00) Ulaanbaatar",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Aus Central W. Standard Time",
    DisplayName: "(UTC+08:45) Eucla",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Transbaikal Standard Time",
    DisplayName: "(UTC+09:00) Chita",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Tokyo Standard Time",
    DisplayName: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "North Korea Standard Time",
    DisplayName: "(UTC+09:00) Pyongyang",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Korea Standard Time",
    DisplayName: "(UTC+09:00) Seoul",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Yakutsk Standard Time",
    DisplayName: "(UTC+09:00) Yakutsk",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Cen. Australia Standard Time",
    DisplayName: "(UTC+09:30) Adelaide",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "AUS Central Standard Time",
    DisplayName: "(UTC+09:30) Darwin",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "E. Australia Standard Time",
    DisplayName: "(UTC+10:00) Brisbane",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "AUS Eastern Standard Time",
    DisplayName: "(UTC+10:00) Canberra, Melbourne, Sydney",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "West Pacific Standard Time",
    DisplayName: "(UTC+10:00) Guam, Port Moresby",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Tasmania Standard Time",
    DisplayName: "(UTC+10:00) Hobart",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Vladivostok Standard Time",
    DisplayName: "(UTC+10:00) Vladivostok",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Lord Howe Standard Time",
    DisplayName: "(UTC+10:30) Lord Howe Island",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Bougainville Standard Time",
    DisplayName: "(UTC+11:00) Bougainville Island",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Russia Time Zone 10",
    DisplayName: "(UTC+11:00) Chokurdakh",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Magadan Standard Time",
    DisplayName: "(UTC+11:00) Magadan",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Norfolk Standard Time",
    DisplayName: "(UTC+11:00) Norfolk Island",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Sakhalin Standard Time",
    DisplayName: "(UTC+11:00) Sakhalin",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Central Pacific Standard Time",
    DisplayName: "(UTC+11:00) Solomon Is., New Caledonia",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Russia Time Zone 11",
    DisplayName: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "New Zealand Standard Time",
    DisplayName: "(UTC+12:00) Auckland, Wellington",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "UTC+12",
    DisplayName: "(UTC+12:00) Coordinated Universal Time+12",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Fiji Standard Time",
    DisplayName: "(UTC+12:00) Fiji",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Kamchatka Standard Time",
    DisplayName: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Chatham Islands Standard Time",
    DisplayName: "(UTC+12:45) Chatham Islands",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "UTC+13",
    DisplayName: "(UTC+13:00) Coordinated Universal Time+13",
    SupportsDaylightSavingTime: false,
  },
  {
    Id: "Tonga Standard Time",
    DisplayName: "(UTC+13:00) Nuku\u0027alofa",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Samoa Standard Time",
    DisplayName: "(UTC+13:00) Samoa",
    SupportsDaylightSavingTime: true,
  },
  {
    Id: "Line Islands Standard Time",
    DisplayName: "(UTC+14:00) Kiritimati Island",
    SupportsDaylightSavingTime: false,
  },
]

const DEFAULT_TIMEZONES = {
  "af-south-1": timezones[62], // South Africa Standard Time
  "ap-east-1": timezones[103], // China Standard Time
  "ap-south-1": timezones[90], // India Standard Time
  "ap-south-2": timezones[90], // India Standard Time
  "ap-northeast-1": timezones[111], // Tokyo Standard Time
  "ap-northeast-2": timezones[113], // Korea Standard Time
  "ap-northeast-3": timezones[111], // Tokyo Standard Time
  "ap-southeast-1": timezones[105], // Singapore Standard Time
  "ap-southeast-2": timezones[118], // AUS Eastern Standard Time
  "ap-southeast-3": timezones[97], // SE Asia Standard Time
  "ca-central-1": timezones[20], // Eastern Standard Time
  "eu-central-1": timezones[53], // Central European Standard Time
  "eu-central-2": timezones[53], // Central European Standard Time
  "eu-west-1": timezones[45], // UTC
  "eu-west-2": timezones[45], // UTC
  "eu-west-3": timezones[53], // Central European Standard Time
  "eu-south-1": timezones[53], // Central European Standard Time
  "eu-south-2": timezones[53], // Central European Standard Time
  "eu-north-1": timezones[53], // Central European Standard Time
  "il-central-1": timezones[64], // Israel Standard Time
  "me-central-1": timezones[76], // Arabian Standard Time
  "me-south-1": timezones[76], // Arabian Standard Time
  "sa-east-1": timezones[33], // E. South America Standard Time
  "us-east-1": timezones[20], // Eastern Standard Time
  "us-east-2": timezones[20], // Eastern Standard Time
  "us-west-1": timezones[9], // Pacific Standard Time
  "us-west-2": timezones[9], // Pacific Standard Time
}

export {
  isDateValid,
  timezones,
  DEFAULT_TIMEZONES,
}
