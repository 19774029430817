import axios from "axios"
import { toast } from "@/toast"

const path = ext => ext ? `/customer/invoices/${ext}` : "/customer/invoices"

const invoice = {
  namespaced: true,
  state: {
    invoices: [],
  },
  mutations: {
    ADD_INVOICES(state, invoices) {
      state.invoices = invoices
    },
  },
  actions: {
    async fetchAllInvoices({ commit }) {
      try {
        const { data } = await axios.get(path())
        commit("ADD_INVOICES", data)
      }
      catch (e) {
        toast.error(e)
      }
    },
    async downloadInvoice(_, { id }) {
      try {
        const { data } = await axios.get(path(id))
        toast.success("Invoice downloaded.")
        window.location.replace(data.downloadUrl)
      }
      catch (e) {
        toast.error(e)
      }
    },
  },
}

export {
  invoice,
}
