<script setup>
import { useDisplay } from "vuetify"
import { useAccount } from "@/composables/useAccount.js"

defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array,
    required: true,
  },
})

const { user } = useAccount()
const { mdAndUp } = useDisplay()
</script>

<template>
  <v-navigation-drawer
    class="bg-secondary"
    :expand-on-hover="mdAndUp"
    :rail="mdAndUp"
    :permanent="mdAndUp"
    :location="mdAndUp ? 'start' : 'bottom'"
  >
    <v-list
      class="bg-secondary"
      nav
    >
      <v-list-item
        :prepend-avatar="user.picture"
        :title="user.name"
        :subtitle="user.email"
        to="/account"
      />
    </v-list>

    <v-divider />

    <v-list
      class="bg-secondary"
      density="compact"
      nav
    >
      <template
        v-for="item in items"
        :key="item.title"
      >
        <v-list-item
          v-if="item.show(user)"
          :to="item.to"
          :title="item.title"
          active-class="nav-item--active"
        >
          <template #prepend>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.nav-item {
  &--active {
    background: linear-gradient(180deg, rgb(2 78 211 / 100%) 0%, rgb(15 103 255 / 100%) 100%);
  }
}
</style>
