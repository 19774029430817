import axios from "axios"
import { toast } from "@/toast"

const unusableRepoIds = [
  4308661, // PRTGTemplateBase - this one is empty
  27800507, // Sugarmate Blood Glucose Monitor - this one has not the required structure
  26959892, // PRTG Device Templates for Micetro DDI Software,
  38506417, // CI-Template
]

const upload = {
  namespaced: true,
  state: {
    gitlabTemplates: [],
    files: {},
    isCached: {},
  },
  mutations: {
    SET_GITLAB_TEMPLATES(state, templates) {
      state.gitlabTemplates = templates.filter(({ id }) => !unusableRepoIds.includes(id))
    },
    SET_CUSTOM_FILES(state, { stackId, files }) {
      state.files = { ...state.files, [stackId]: files }
    },
    SET_IS_CACHED(state, { stackId, isCached }) {
      state.isCached[stackId] = isCached
    },
  },
  actions: {
    async fetchGitlabTemplates({ state, commit }) {
      if (state.gitlabTemplates.length > 0) return

      try {
        const { data } = await axios.get(
          "https://gitlab.com/api/v4/groups/1482357/projects?per_page=100&order_by=name&sort=asc",
        )
        commit("SET_GITLAB_TEMPLATES", data)
      }
      catch (e) {
        toast.error(e)
      }
    },
    async fetchFilesFromS3({ state, commit }, stackId) {
      if (state.files[stackId] && state.isCached[stackId]) return state.files[stackId]

      try {
        const { data: files } = await axios.get(`/instance/${stackId}/upload/s3`)
        commit("SET_CUSTOM_FILES", { stackId, files })
        commit("SET_IS_CACHED", { stackId, isCached: true })
        return files
      }
      catch (e) {
        toast.error(e)
        return {}
      }
    },
  },
}

export {
  upload,
}
